import _ from 'lodash';
import { VehicleRequisitionContextActions } from './VehicleRequisitionContextActions';
import { vehicleRequisitionInitialState } from './VehicleRequisitionInitialState';
import clearAllChecksForOptions from '../../utilities/VehicleRequistionContextUtils';
import { REQUISITION_TYPE } from '../../pages/non-standard-purchase/constants';
import { noDuplicateOptions } from '../../constants/utils';

const vehicleRequisitionContextReducer = (state, action) => {
  function getEntityName() {
    let entityName = sessionStorage.getItem('orderingOffice');
    //  if ordering office is not available then use bureau
    if (!entityName || _.isEmpty(entityName.trim())) {
      entityName = sessionStorage.getItem('orderingBureau');
      if (!entityName || _.isEmpty(entityName.trim())) {
        //  This is to make sure there is a blank entity name when nothing is available
        entityName = 'X-INIT';
      }
    }
    [, entityName] = entityName.split('-');
    return entityName;
  }

  // DEBUGGING - do not remove
  // console.log({
  //   action: action.type,
  //   currentState: state,
  //   payload: action.payload,
  // });
  switch (action.type) {
    case VehicleRequisitionContextActions.UPDATE_CURRENT_STANDARD_ITEM: {
      return { ...state, currentStandardItem: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_COMPARE_VEHICLES_CURRENT_PAGE: {
      return { ...state, compareVehiclesCurrentPage: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_ALL_ACTIVE_CONTRACTS: {
      return { ...state, allActiveContracts: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_VEHICLE_DATA_FLATTENED: {
      return { ...state, vehicleDataFlattened: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DISPLAYING_ACTIVE_CONTRACTS: {
      return { ...state, displayingActiveContracts: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_ALL_MINIMUM_REQS: {
      return { ...state, allMinimumRequirements: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DISPLAYING_MINIMUM_REQS: {
      return { ...state, displayingMinRequirements: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_ALL_OPTIONAL_REQS: {
      return {
        ...state,
        allOptionalRequirements: action.payload,
        optionalReqData: action.optionalReqData,
        deliveryOptions: action.deliveryOptions,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_DELIVERY_OPTIONS: {
      return {
        ...state,
        deliveryOptions: action.payload,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_DISPLAYING_OPTIONAL_REQS: {
      return { ...state, displayingOptionalRequirements: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_ALL_VEHICLE_OVERVIEW_DATA: {
      return { ...state, allVehicleOverview: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DISPLAYING_VEHICLE_OVERVIEW_DATA: {
      return { ...state, displayingVehicleOverview: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_ALL_COST_BREAKDOWN_DATA: {
      return { ...state, allCostBreakdown: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DISPLAYING_COST_BREAKDOWN_DATA: {
      return { ...state, displayingCostBreakdown: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT: {
      return { ...state, selectedContract: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DRAFT_REQUISITION: {
      const oldDraftRequisition = state.draftRequisition || {};
      return {
        ...state,
        draftRequisition: { ...oldDraftRequisition, ...action.payload },
      };
    }
    case VehicleRequisitionContextActions.UPDATE_INACTIVE_CONTRACT: {
      return { ...state, isInactiveContract: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_SELECTED_OPTIONS_FOR_PRICE: {
      return {
        ...state,
        selectedOptionsForPrice: noDuplicateOptions(action.payload),
      };
    }
    case VehicleRequisitionContextActions.UPDATE_FEATURES_SECTION: {
      return {
        ...state,
        vehicleDataFlattened: action.vehicleDataFlattened,
        allVehicleOverview: action.allVehicleOverview,
        allVehicleInformation: action.allVehicleInformation,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_MIN_REQ_SECTION: {
      return {
        ...state,
        minimumRequirementsFlattened: action.minimumRequirementsFlattened,
        allMinimumRequirements: action.allMinimumRequirements,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_COMPARE_VEHICLES_PAGE_INFO: {
      return {
        ...state,
        displayingOtherFeatures: [...action.displayingOtherFeatures],
        displayingVehicleOverview: [...action.displayingVehicleOverview],
        displayingEngineFuel: action.displayingEngineFuel,
        displayingMinRequirements: [...action.displayingMinRequirements],
        displayingActiveContracts: [...action.displayingActiveContracts],
        displayingOptionalRequirements: [
          ...action.displayingOptionalRequirements,
        ],
        displayingCostBreakdown: [...action.displayingCostBreakdown],
      };
    }
    case VehicleRequisitionContextActions.UPDATE_VEHICLE_QUANTITY: {
      return { ...state, vehicleQuantity: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_VEHICLE_QUANTITY_HAS_ERROR: {
      return { ...state, quantityHasError: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DRAFT_PROPERTIES: {
      const spreadIfDefined = (key) => {
        return action[key] !== undefined ? { [key]: action[key] } : {};
      };

      return {
        ...state,
        draftRequisition: action.draftRequisition,
        ...spreadIfDefined('isAreq'),
        ...spreadIfDefined('additionalRequirements'),
        ...spreadIfDefined('agencyReferenceNumber'),
        ...spreadIfDefined('draftSelectedColors'),
        ...spreadIfDefined('nonLowBidJustification'),
        ...spreadIfDefined('vehicleQuantity'),
        ...spreadIfDefined('selectedOptionsForPrice'),
        ...spreadIfDefined('requiredOptionSessionState'),
        ...spreadIfDefined('taggedOptions'),
        ...spreadIfDefined('paintAndGraphicsOptions'),
        ...spreadIfDefined('engineerTaggedOptions'),
        ...spreadIfDefined('sin'),
        ...spreadIfDefined('isCalculatePriceDisabled'),
        ...spreadIfDefined('currentStep'),
        ...spreadIfDefined('selectedContractAgencyInformation'),
        ...spreadIfDefined('requisitionStateContext'),
        ...spreadIfDefined('mailingStateContext'),
        ...spreadIfDefined('deliveryStateContext'),
        ...spreadIfDefined('submitComment'),
        ...spreadIfDefined('contractLineId'),
        ...spreadIfDefined('selectedContract'),
        ...spreadIfDefined('specialDeliveryInstructions'),
        ...spreadIfDefined('deliveryDate'),
        ...spreadIfDefined('urgentRequirementJustification'),
        ...spreadIfDefined('nonSopData'),
        ...spreadIfDefined('requisitionType'),
      };
    }
    case VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE: {
      return { ...state, addOptionsState: [...action.payload] };
    }
    case VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_WITH_COLLISIONS: {
      return { ...state, addOptionsDataWithCollisions: [...action.payload] };
    }
    case VehicleRequisitionContextActions.UPDATE_SELECTED_ITEMS_FOR_PURCHASE: {
      return { ...state, selectedItemsForPurchaseState: [...action.payload] };
    }
    case VehicleRequisitionContextActions.UPDATE_OPTIONS_WITH_COLLISIONS_PROPERTIES: {
      return {
        ...state,
        addOptionsState: [...action.addOptionsState],
        addOptionsDataWithCollisions: [...action.addOptionsDataWithCollisions],
      };
    }
    case VehicleRequisitionContextActions.RESET_OLD_SELECTED_CONTRACT_INFO: {
      return {
        ...state,
        vehicleColors: [],
        requisitionHasError: [],
        selectedContract: action.selectedContract,
        selectedContractCostBreakdown: action.selectedContractCostBreakdown,
        currentStep: action.currentStep,
      };
    }
    case VehicleRequisitionContextActions.RESET_EXPIRED_SELECTED_CONTRACT_INFO: {
      return {
        ...state,
        vehicleColors: [],
        draftSelectedColors: [],
        requisitionHasError: [],
        selectedContract: null,
        contractNumber: '',
        contractModification: '',
        scheduleLine: '',
        currentStep: action.currentStep,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO: {
      return {
        ...state,
        selectedContract: action.selectedContract,
        selectedContractCostBreakdown: action.selectedContractCostBreakdown,
        currentStep: action.currentStep
          ? action.currentStep
          : state.currentStep,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_HAS_ERROR: {
      return {
        ...state,
        requisitionHasError: action.payload,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_AREQ_VENDOR_QUOTES: {
      return {
        ...state,
        areqVendorQuoteDetails: action.payload,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_AREQ_PENDING_CUSTOMER_RESPONSE: {
      return {
        ...state,
        areqPendingCustomerResponse: { ...action.payload },
      };
    }
    case VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS: {
      return { ...state, vehicleColors: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC: {
      return { ...state, paintAndGraphicsOptions: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS: {
      return {
        ...state,
        taggedOptions: action.payload.taggedOptions,
        engineerTaggedOptions: action.payload.engineerTaggedOptions,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_ENGINEER_TAGGED_OPTIONS: {
      return { ...state, engineerTaggedOptions: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS_CUSTOMER_INPUT: {
      const { taggedOptions } = state;
      const updatedTaggedOptions = taggedOptions?.map((option) => {
        const { optionCode } = option;
        return action.payload.optionCode === optionCode
          ? { ...option, customerInput: action.payload.description }
          : option;
      });
      return { ...state, taggedOptions: updatedTaggedOptions };
    }
    case VehicleRequisitionContextActions.UPDATE_NON_LOW_BID_JUSTIFICATION: {
      return { ...state, nonLowBidJustification: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_AVAILABLE_COLORS: {
      return { ...state, availableColors: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_VEHICLE_REQUISITION_METHODS: {
      return {
        ...state,
        sin: action.sin,
        draftId: action.draftId,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_PURCHASE_COLLISIONS_INFO: {
      return { ...state, getPurchaseCollisionInfo: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION: {
      return {
        ...state,
        requiredOptionSessionState: noDuplicateOptions(action.payload),
      };
    }
    case VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_BUTTON_DISABLED: {
      return {
        ...state,
        isCalculatePriceDisabled: action.isCalculatePriceDisabled,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_ERROR_AND_BUTTON_DISABLED: {
      return {
        ...state,
        isCalculatePriceError: action.payload,
        isCalculatePriceDisabled: action.isCalculatePriceDisabled,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_ERRORS: {
      const errorCount = Object.values(action.payload).filter((entry) => {
        return entry;
      }).length;

      return {
        ...state,
        addOptionErrors: errorCount,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_ERROR: {
      return { ...state, isCalculatePriceError: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_CONTRACT_AGENCY_INFORMATION: {
      return { ...state, selectedContractAgencyInformation: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_CURRENT_REQUISITION_STEP: {
      return { ...state, currentStep: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_FETCH_STATES: {
      return { ...state, statesRequisitionFetchedFrmContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_MAILING_FETCH_STATES: {
      return { ...state, statesMailingFetchedFrmContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DELIVERY_FETCH_STATES: {
      return { ...state, statesDeliveryFetchedFrmContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_ADDRESS_VERIFICATION_MODAL: {
      return { ...state, uspsAddressVerificationModalState: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_VALIDATED_ADDRESS: {
      return { ...state, reqValidatedAddress: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_MAILING_VALIDATED_ADDRESS: {
      return { ...state, mailingValidatedAddress: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DELIVERY_VALIDATED_ADDRESS: {
      return { ...state, deliveryValidatedAddress: action.payload };
    }
    case VehicleRequisitionContextActions.RESET_USPS_VALIDATION: {
      return {
        ...state,
        reqValidatedAddress: {},
        mailingValidatedAddress: {},
        deliveryValidatedAddress: {},
        validatedAddressNotFound: [],
        uspsModalOptionsSelectedState: [],
      };
    }
    case VehicleRequisitionContextActions.UPDATE_VALIDATED_ADDRESS_NOT_FOUND: {
      return {
        ...state,
        validatedAddressNotFound: [
          ...state.validatedAddressNotFound,
          action.payload,
        ],
      };
    }
    case VehicleRequisitionContextActions.CLEAR_VALIDATED_ADDRESS_NOT_FOUND: {
      return {
        ...state,
        validatedAddressNotFound: action.payload,
      };
    }

    case VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE: {
      return {
        ...state,
        uspsModalOptionsSelectedState: [
          ...state.uspsModalOptionsSelectedState,
          action.payload,
        ],
      };
    }
    case VehicleRequisitionContextActions.UPDATE_USPS_MODAL_OPTIONS_SELECTED_STATE: {
      return { ...state, uspsModalOptionsSelectedState: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE: {
      return { ...state, requisitionStateContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_MAILING_STATE: {
      return { ...state, mailingStateContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_CUSTOM_PAINT_OPTIONS: {
      return { ...state, customPaintOptionsContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE: {
      return { ...state, deliveryStateContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_INITIAL_DELIVERY_STATE: {
      return {
        ...state,
        deliveryStateContext: {
          ...vehicleRequisitionInitialState.deliveryStateContext,
          entityName: getEntityName(),
        },
      };
    }
    case VehicleRequisitionContextActions.UPDATE_INITIAL_MAILING_STATE: {
      return {
        ...state,
        mailingStateContext: {
          ...vehicleRequisitionInitialState.mailingStateContext,
          entityName: getEntityName(),
        },
      };
    }
    case VehicleRequisitionContextActions.UPDATE_INITIAL_REQUISITION_STATE: {
      return {
        ...state,
        requisitionStateContext: {
          ...vehicleRequisitionInitialState.reqAddressStateContext,
          entityName: getEntityName(),
        },
      };
    }
    case VehicleRequisitionContextActions.UPDATE_DEALERSHIP_DELIVERY: {
      return { ...state, dealershipDeliveryContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_GET_CATALOGS_FOR_CATEGORY_CALLING_CODES: {
      return { ...state, countryCallingCodesContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_FILE_UPLOAD_SECTION: {
      return { ...state, fileUpload: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_TOTAL_UPLOADED_FILES: {
      return { ...state, totalUploadedFiles: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_ADDRESS_HAS_ERROR: {
      return { ...state, requisitionAddressErrorContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_MAILING_HAS_ERROR: {
      return { ...state, mailingAddressErrorContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_DELIVERY_HAS_ERROR: {
      return { ...state, deliveryAddressErrorContext: action.payload };
    }
    case VehicleRequisitionContextActions.UPDATE_REQ_ADDRESS_STATE: {
      return { ...state, reqAddressStateContext: action.payload };
    }
    case VehicleRequisitionContextActions.VALIDATIONS_ERROR_STATE: {
      return { ...state, requisitionValidationState: action.payload };
    }
    case VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN: {
      return { ...state, isValidationErrorExist: action.payload };
    }
    case VehicleRequisitionContextActions.SET_NOTIFY_MODAL_OPEN: {
      return { ...state, isNotifyModal: action.payload };
    }
    case VehicleRequisitionContextActions.SET_REQ_NUMBER_VALIDATIONS_MODAL_OPEN: {
      return { ...state, isReqNumberModalOpen: action.payload };
    }
    case VehicleRequisitionContextActions.RESET_REQUISITION_STATE: {
      return {
        ...vehicleRequisitionInitialState,
        requisitionStateContext: {
          ...vehicleRequisitionInitialState.requisitionStateContext,
          entityName: getEntityName(),
        },
        mailingStateContext: {
          ...vehicleRequisitionInitialState.mailingStateContext,
          entityName: getEntityName(),
        },
        deliveryStateContext: {
          ...vehicleRequisitionInitialState.deliveryStateContext,
          entityName: getEntityName(),
        },
      };
    }
    case VehicleRequisitionContextActions.UPDATE_INITIAL_ENTITY_NAME: {
      return {
        ...state,
        requisitionStateContext: {
          entityName: getEntityName(),
        },
        mailingStateContext: {
          entityName: getEntityName(),
        },
        deliveryStateContext: {
          entityName: getEntityName(),
        },
      };
    }
    case VehicleRequisitionContextActions.UPDATE_CALCULATED_PRICE_INFO: {
      return {
        ...state,
        calculatedPriceData: action.calculatedPriceData,
      };
    }
    case VehicleRequisitionContextActions.RESET_CONTRACT_SELECTIONS: {
      const clearAllOldState = clearAllChecksForOptions(
        state.displayingOptionalRequirements,
      );
      const clearDeliveryOptions = clearAllChecksForOptions(
        state.deliveryOptions,
      );
      return {
        ...state,
        vehicleColors: [],
        requisitionHasError: [],
        addOptionsState: clearAllOldState,
        addOptionsDataWithCollisions: clearAllOldState,
        selectedItemsForPurchaseState: [],
        requiredOptionSessionState: [],
        deliveryOptions: clearDeliveryOptions,
        getPurchaseCollisionInfo: [],
        selectedOptionsForPrice: [],
        isCalculatePriceDisabled: true,
        vehicleQuantity: '1',
        quantityHasError: false,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_MODAL: {
      return {
        ...state,
        submitRequisitionModal: action.payload,
      };
    }

    case VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING: {
      return {
        ...state,
        submitRequisitionLoading: action.payload,
      };
    }

    case VehicleRequisitionContextActions.CANCEL_REQUISITION_MODAL: {
      return {
        ...state,
        cancelRequisitionModal: action.payload,
      };
    }

    case VehicleRequisitionContextActions.SET_FORM_ERROR_FOCUS: {
      return {
        ...state,
        formErrorFocus: action.payload,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_REQUISITION_SUBMIT_COMMENT: {
      return {
        ...state,
        submitComment: action.payload,
      };
    }

    case VehicleRequisitionContextActions.SUBMIT_REQUISITION_SUCCESS_ACTION: {
      return {
        ...state,
        submitRequisitionSuccessAction: action.payload,
      };
    }

    case VehicleRequisitionContextActions.SELECTED_AGECY_ORGANIZATION_CODE: {
      return { ...state, selectedAgencyOrganizationCode: action.payload };
    }

    case VehicleRequisitionContextActions.IS_DOMESTIC_SHIPMENT: {
      return { ...state, isDomesticShipment: action.payload };
    }

    case VehicleRequisitionContextActions.SET_IS_AREQ: {
      return {
        ...state,
        requisitionType:
          action.payload === true
            ? REQUISITION_TYPE.AREQ
            : state.requisitionType,
        isAreq: action.payload,
      };
    }

    case VehicleRequisitionContextActions.EXCLUDE_AREQ_OPTION: {
      return { ...state, excludeAreqOption: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_AREQ_PRINT_PREVIEW: {
      const { areqPrintPreview } = state;
      return {
        ...state,
        areqPrintPreview: { ...areqPrintPreview, ...action.payload },
      };
    }

    case VehicleRequisitionContextActions.UPDATE_SOP_PRINT_PREVIEW: {
      const { sopPrintPreview } = state;
      return {
        ...state,
        sopPrintPreview: { ...sopPrintPreview, ...action.payload },
      };
    }

    case VehicleRequisitionContextActions.UPDATE_MAS_PRINT_PREVIEW: {
      const { masPrintPreview } = state;
      return {
        ...state,
        masPrintPreview: { ...masPrintPreview, ...action.payload },
      };
    }

    case VehicleRequisitionContextActions.UPDATE_URGENT_REQ_PRINT_PREVIEW: {
      const { urgReqPrintPreview } = state;
      return {
        ...state,
        urgReqPrintPreview: { ...urgReqPrintPreview, ...action.payload },
      };
    }

    case VehicleRequisitionContextActions.UPDATE_AGENCY_REF_NUMBER: {
      return {
        ...state,
        agencyReferenceNumber: action.payload,
      };
    }

    case VehicleRequisitionContextActions.UPDATE_ADDITIONAL_REQUIREMENTS: {
      return {
        ...state,
        additionalRequirements: action.payload,
      };
    }

    case VehicleRequisitionContextActions.UPDATE_NON_SOP_OPTION: {
      return { ...state, nonSopSelectedOption: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_SPECIAL_DELIVERY_INSTRUCTIONS: {
      return { ...state, specialDeliveryInstructions: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_DELIVERY_DATE: {
      return { ...state, deliveryDate: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_JUSTIFICATION: {
      return { ...state, urgentRequirementJustification: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_NON_SOP_DATA: {
      return { ...state, nonSopData: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_SPECS: {
      return { ...state, masRequirementSpecs: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_URG_REQ_ERROR_BANNER: {
      return { ...state, urgentReqErrorBanner: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_MAS_REQ_ERROR_BANNER: {
      return { ...state, masReqErrorBanner: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION: {
      return { ...state, urgentReqJustification: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_AREQ_JUSTIFICATION: {
      return { ...state, areqJustification: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_CURRENT_STEP: {
      return { ...state, urgentRequirementCurrentStep: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_CURRENT_STEP: {
      return { ...state, masRequirementCurrentStep: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_STEPS_INDICATOR: {
      return { ...state, urgentReqStepsProcessIndicator: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_STEPS_INDICATOR: {
      return { ...state, masReqStepsProcessIndicator: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_REVIEW_SUBMIT_TAB: {
      return { ...state, reviewSubmitTab: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_FILES_UPLOADED_LIST: {
      return { ...state, filesUploadedList: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_URG_REQUIREMENT_DELIVERY_DATE: {
      return { ...state, deliveryDate: action.payload };
    }

    case VehicleRequisitionContextActions.SET_REQUISITION_TYPE: {
      return { ...state, requisitionType: action.payload };
    }

    case VehicleRequisitionContextActions.SET_IS_LEASING_ADDITIONAL_REQUIREMENTS_PREVIEW: {
      return {
        ...state,
        isLeasingAdditionalRequirementsPreview: action.payload,
      };
    }

    case VehicleRequisitionContextActions.SET_REQUISITION_ATTACHMENT_TYPES: {
      return {
        ...state,
        requisitionAttachmentTypes: action.payload,
      };
    }

    case VehicleRequisitionContextActions.UPDATE_ENGINE_FUEL_DATA: {
      return { ...state, engineFuelData: action.payload };
    }

    case VehicleRequisitionContextActions.UPDATE_FUEL_CODES: {
      return {
        ...state,
        fuelCodes: action.payload,
      };
    }
    case VehicleRequisitionContextActions.UPDATE_PRE_DRAFT_COMMENTS: {
      return {
        ...state,
        preDraftComments: action.payload,
      };
    }

    case VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_PLACE_ORDER_STATE: {
      return {
        ...state,
        urgentRequirementPlaceOrderInitialState: action.payload,
      };
    }

    case VehicleRequisitionContextActions.UPDATE_MAS_PLACE_ORDER_STATE: {
      return { ...state, masPlaceOrderInitialState: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};

export default vehicleRequisitionContextReducer;
