import './withDraftRequisition.scss';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Modal, Button, Spinner } from '@gsa/afp-component-library';
import { cloneDeep } from 'lodash';
import {
  GET_ACTIVE_CONTRACTS,
  GET_AGENCIES_BY_PERMISSION,
  GET_OPTIONAL_REQ,
  GET_REQUISITION_CART,
} from '../../services/data-layer';
import { useQueryParam } from '../../hooks/useQueryParam';
import {
  getGroupedOptionalRequirementsData,
  getSimplifiedContractData,
} from '../../utilities/CompareVehicleUtils';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import useLocalState from '../../hooks/useLocalState';
import { GET_STANDARD_ITEM_BY_ID } from '../../services/data-layer/standards';
import { StoreSubjects } from '../../constants/constants';
import {
  REQUISITION_TYPE,
  isAreqType,
} from '../../pages/non-standard-purchase/constants';
import { setSessionStorageItems } from '../../utilities/commonUtils';
import { getRequisitionTransactionType } from '../../pages/VehicleRequisition/utils/RequisitionStepsUtils';
import { withoutAREQOption } from '../../constants/utils';

let requisitionCartValidations = null;
const withDraftRequisition = (WrappedComponent, isUrgentReq = false) => {
  const query = useQueryParam();
  const lastStepRef = useRef(null);
  const sin = query.get('sin');
  const domestic = query.get('domestic');
  const requisitionTypeParam = query.get('requisitionType');
  const [currentSin] = useState(sin);
  const [systemChangeModalData, setSystemChangeModalData] = useState([]);
  const [systemChanges, setSystemChanges] = useState([]);
  const {
    state,
    dispatch,
    getPurchaseCollisionDataOnYes,
    getCalculatedPriceData,
    getattachmentTypes,
  } = useContext(VehicleRequisitionContext);
  const [, setRequsitionAction] = useLocalState('requsitionAction');
  const selectedStateFrmSession = sessionStorage.getItem('selectedState');
  const selectedShipmentCategory = useRef(
    domestic === 'true' ? selectedStateFrmSession : 'EXPORT',
  );
  const draftId = query.get('requisitionId');
  const history = useHistory();

  const initialRender = useRef(true);

  const {
    draftRequisition,
    allActiveContracts,
    calculatedPriceData,
    selectedOptionsForPrice,
    requisitionValidationState,
    isValidationErrorExist,
    requisitionType,
    fuelCodes,
  } = state;

  const [
    getActiveContractsData,
    { data: activeContractsData, loading: activeContractsDataLoading },
  ] = useLazyQuery(GET_ACTIVE_CONTRACTS, {
    fetchPolicy: 'network-only',
  });

  const [
    getOptionalReqData,
    { data: optionalReqData, loading: optionalReqLoading },
  ] = useLazyQuery(GET_OPTIONAL_REQ, {
    fetchPolicy: 'c',
  });

  const [
    getStandardItemData,
    { data: standardItemData, loading: standardItemDataLoading },
  ] = useLazyQuery(GET_STANDARD_ITEM_BY_ID, {
    fetchPolicy: 'c',
  });

  useEffect(() => {
    if (
      allActiveContracts &&
      allActiveContracts.length &&
      calculatedPriceData &&
      calculatedPriceData.calculatePurchaseReqPrice &&
      calculatedPriceData.calculatePurchaseReqPrice.modelCostBreakDown
    ) {
      const {
        calculatePurchaseReqPrice: { modelCostBreakDown },
      } = calculatedPriceData;
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ALL_COST_BREAKDOWN_DATA,
        payload: modelCostBreakDown,
      });
      initialRender.current = false;
    }
  }, [allActiveContracts, calculatedPriceData]);

  const setValidationsModal = (isOpen) => {
    return dispatch({
      type: VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN,
      payload: isOpen,
    });
  };

  const updateShipmentInfo = (value) => {
    return dispatch({
      type: VehicleRequisitionContextActions.IS_DOMESTIC_SHIPMENT,
      payload: value,
    });
  };

  const setValidationProperties = () => {
    if (
      requisitionCartValidations &&
      requisitionCartValidations.systemAvailability &&
      requisitionCartValidations.systemAvailability.isSINexpired
    ) {
      setValidationsModal(true);
      setSystemChanges(requisitionCartValidations);
      setSystemChangeModalData({
        modalTitle:
          "We're sorry, the Standard Item Number for this vehicle has become unavailable",
        modalSubTitle:
          'Since the Standard Item Number for this vehicle has become unavailable, you will have to cancel this requisition to continue. To select another Standard Item Number, you will have to start a new requisition.',
      });
    } else if (
      requisitionCartValidations &&
      ((requisitionCartValidations.lowPriceChange &&
        requisitionCartValidations.lowPriceChange.oldLowPrice != null &&
        requisitionCartValidations.lowPriceChange.newLowPrice != null) ||
        (requisitionCartValidations.modelYearChange &&
          requisitionCartValidations.modelYearChange.oldModelYear != null &&
          requisitionCartValidations.modelYearChange.newModelYear != null) ||
        (requisitionCartValidations.purchaseFeeChange &&
          requisitionCartValidations.purchaseFeeChange.oldPurchaseRate !=
            null &&
          requisitionCartValidations.purchaseFeeChange.newPurchaseRate !=
            null) ||
        (requisitionCartValidations.optionPricesChanges &&
          requisitionCartValidations.optionPricesChanges.length > 0) ||
        (requisitionCartValidations.optionsUnavailable &&
          requisitionCartValidations.optionsUnavailable.length > 0) ||
        (requisitionCartValidations.optionCollisionChanges &&
          requisitionCartValidations.optionCollisionChanges.length > 0))
    ) {
      setValidationsModal(true);
      setSystemChanges(requisitionCartValidations);

      setSystemChangeModalData({
        modalTitle:
          "We're sorry, there have been changes to your selected vehicle",
        modalSubTitle: `Please review your previous selections to ensure these changes are acceptable. 
           You may select new options from the Compare and Select page to continue, or return
           to Requisitions and Orders to cancel this requisition.`,
      });
    } else if (
      requisitionCartValidations &&
      requisitionCartValidations.vehicleAvailability &&
      requisitionCartValidations.vehicleAvailability.isContractExpired
    ) {
      setValidationsModal(true);
      setSystemChanges(requisitionCartValidations);
      setSystemChangeModalData({
        modalTitle:
          'The vehicle you previously selected is no longer available.',
        modalSubTitle: '',
      });
    }
  };

  const setDraftProperties = (draftData) => {
    // testing requisitionCartValidations
    // remove the debug data when AFP13480 and 13481 are accepted
    const debugMode = query.get('REQdebugMode');
    requisitionCartValidations = debugMode
      ? {
          vehicleAvailability: {
            isContractExpired: debugMode === 'T1',
            makeName: 'Ford',
            modelName: 'F150',
          },
          purchaseFeeChange: {
            oldPurchaseRate: 50,
            newPurchaseRate: 150,
            difference: 100,
          },
          lowPriceChange: {
            oldVendor: 'OV',
            newVendor: 'NV',
            oldLowPrice: 100,
            newLowPrice: 200,
          },
          modelYearChange: {
            oldModelYear: 2020,
            newModelYear: 2021,
          },
          systemAvailability: {
            isSINexpired: debugMode === 'T2',
            makeName: '',
            modelName: '',
          },
        }
      : draftData.validations;

    const {
      standardItemId,
      vehicles,
      justification,
      clientData: { selectedOptions, clientState },
      quantity: draftQuantity,
      finSignalCode: signalCode,
      finFundCode: fundCode,
      finServiceCode: serviceCode,
      boac,
      julianDate,
      serialNumber,
      finSupplementaryAddress: signalSupplementaryAddress,
      finAdditionalFundsPerUnit: additionalAreqFunds,
      // finAdditionalFinancialInformation: agencyFinancialData,
      customerAssignedNumber: agencyOrderNumber,
      markForInformation,
      transportationControlNumber,
      accountingClassificationReferenceNumber,
      nationalStockNumber,
      registrationNumber,
      requisitionerContact,
      requisitionerAddress,
      mailingAddress,
      mailingContact,
      deliveryAddress,
      deliveryContact,
      unitPriceToCustomer,
      isAreq,
      additionalRequirements,
      agencyReferenceNumber,
      specialDeliveryInstructions,
      deliveryDate,
      nonSopData,
      contractLineId,
      agencyInfo,
      bureauInfo,
      officeInfo,
      finAdditionalInfo,
      agencyContact,
    } = draftData;

    // const {
    //   email: email,
    //   firstName,
    //   lastName,
    //   phoneNumber,
    // } = requisitionerContact || {};

    const standardItem = JSON.parse(sessionStorage.getItem('standardItem'));
    const agencyInformationFromDraft = {
      agency: sessionStorage.getItem('orderingAgency'),
      bureau: sessionStorage.getItem('orderingBureau'),
      office: sessionStorage.getItem('orderingOffice'),
      bureauName: bureauInfo && bureauInfo.name ? bureauInfo.name : '',
      agencyName: agencyInfo && agencyInfo.name ? agencyInfo.name : '',
      groupAssignment:
        officeInfo && officeInfo.officeCode ? officeInfo.officeCode : '',
      signalCode,
      fundCode,
      serviceCode,
      requisitionBOAC: requisitionTypeParam === 'clone' ? null : boac,
      requisitionJulian: julianDate,
      requisitionSerialNumber:
        requisitionTypeParam === 'clone' ? null : serialNumber,
      signalSupplementaryAddress,
      markForInformation,
      transportationControlNumber,
      accountingClassificationReferenceNumber,
      nationalStockNumber,
      registrationNumber,
      finAdditionalFundsPerUnit: additionalAreqFunds
        ? additionalAreqFunds.toString()
        : '',
      additionalAreqFunds: additionalAreqFunds
        ? additionalAreqFunds.toString()
        : '',
      agencyFinancialData: finAdditionalInfo,
      agencyOrderNumber:
        requisitionTypeParam === 'clone' ? null : agencyOrderNumber,
      email: agencyContact.email,
      firstName: agencyContact.firstName,
      lastName: agencyContact.lastName,
      phoneCountryCallingCode: agencyContact.phoneCountryCode,
      phoneNumber: agencyContact.phoneNumber,
      phoneExtension: agencyContact.phoneExtension,
      faxCountryCallingCode: agencyContact.faxCallingCode,
      faxNumber: agencyContact.faxNumber,
      faxExtension: agencyContact.faxExtension,
      unitPrice: unitPriceToCustomer,
      unitPriceWithAreqs:
        unitPriceToCustomer && additionalAreqFunds
          ? (unitPriceToCustomer + additionalAreqFunds).toString()
          : '',
      treasuryAccountSymbol: clientState?.validatedTas,
    };
    // Condition when a requisition is classified as Lease
    const requisitionTransactionType = getRequisitionTransactionType(
      sessionStorage.getItem('agencyCode'),
      sessionStorage.getItem('bureauCode'),
    );

    const requistionAddressAndContact = {
      ...requisitionerAddress,
      ...requisitionerContact,
      rankAndFullName: requisitionerAddress?.entityName,
      militaryOrDiplomaticAddress: requisitionerAddress?.addressLine1,
      militaryAddress: requisitionerAddress?.addressLine1,
      postOffice: requisitionerAddress?.militaryPostOffice,
    };
    const mailingAddressAndContact = {
      ...mailingAddress,
      ...mailingContact,
      rankAndFullName: mailingAddress?.entityName,
      militaryOrDiplomaticAddress: mailingAddress?.addressLine1,
      militaryAddress: mailingAddress?.addressLine1,
      postOffice: mailingAddress?.militaryPostOffice,
    };
    const deliveryAddressAndContact = {
      ...deliveryAddress,
      ...deliveryContact,
    };
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_DRAFT_PROPERTIES,
      draftRequisition: draftData,
      draftSelectedColors: vehicles,
      nonLowBidJustification: justification,
      vehicleQuantity: requisitionTypeParam === 'clone' ? null : draftQuantity,
      //  sin: standardItemCode,
      selectedOptionsForPrice: selectedOptions.selectedOptions || [],
      requiredOptionSessionState: selectedOptions.selectedOptions || [],
      taggedOptions: selectedOptions?.customerInputs?.taggedOptions || [],
      paintAndGraphicsOptions:
        selectedOptions?.customerInputs?.paintAndGraphicsOptions || [],
      engineerTaggedOptions:
        selectedOptions?.customerInputs?.engineerTaggedOptions || [],
      isCalculatePriceDisabled: true,
      isAreq,
      specialDeliveryInstructions,
      deliveryDate,
      requisitionType,
      nonSopData,
      additionalRequirements,
      agencyReferenceNumber,
      currentStep: clientState.currentStep,
      submitComment: clientState.submitComment,
      nonSopDataJustification:
        clientState?.nonSopData?.urgentReqJustification?.justification,
      urgentReqJustification:
        clientState?.urgentRequirement?.urgentReqJustification,
      urgentRequirementCurrentStep:
        clientState?.urgentRequirement?.urgentRequirementCurrentStep,
      urgentReqStepsProcessIndicator:
        clientState?.urgentRequirement?.urgentReqStepsProcessIndicator,
      contractLineId: parseInt(contractLineId, 10),
      selectedContract: allActiveContracts?.find(
        (contract) => contract.contractLineId === contractLineId?.toString(),
      ),
      selectedContractAgencyInformation: agencyInformationFromDraft,
      requisitionStateContext: requistionAddressAndContact,
      mailingStateContext: mailingAddressAndContact,
      deliveryStateContext: deliveryAddressAndContact,
    });
    //   setCurrentSin(standardItem.Code);
    if (
      (standardItem?.id || standardItemId) &&
      requisitionType !== REQUISITION_TYPE.URGENT_REQUIREMENT
    ) {
      getStandardItemData({
        variables: {
          filter: {
            operator: 'EQ',
            key: 'id',
            value: standardItemId,
          },
        },
      });
      getActiveContractsData({
        variables: {
          standardItemId: standardItemId?.toString(),
          transactionType: requisitionTransactionType,
        },
      });
      getOptionalReqData({
        variables: {
          standardItemId,
        },
      });
      const standardItemID = standardItemId || parseInt(standardItem?.id, 10);
      if (standardItemID) {
        getPurchaseCollisionDataOnYes({
          variables: {
            standardItemId: standardItemID,
            selectedOptions: selectedOptions.selectedOptions || [],
          },
        });
        getCalculatedPriceData({
          variables: {
            standardItemId: standardItemID,
            quantity: Number(draftQuantity),
            selectedOptions: withoutAREQOption(selectedOptions.selectedOptions),
            transactionType: requisitionTransactionType,
          },
        });
      }
      setValidationProperties();
    }
  };

  const [getDraftData] = useLazyQuery(GET_REQUISITION_CART, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getRequisition }) => {
      setSessionStorageItems(getRequisition);

      setDraftProperties(getRequisition);

      if (getRequisition.standardItemId) {
        getStandardItemData({
          variables: {
            filter: {
              operator: 'EQ',
              key: 'id',
              value: getRequisition.standardItemId,
            },
          },
        });
      }
      if (getRequisition?.clientData?.clientState?.isDomesticShipment) {
        updateShipmentInfo(true);
      }

      if (getRequisition?.clientData?.clientState?.shipmentLocation) {
        sessionStorage.setItem(
          'shipmentLocation',
          getRequisition?.clientData?.clientState?.shipmentLocation,
        );
      }

      if (getRequisition?.clientData?.clientState?.selectedState) {
        sessionStorage.setItem(
          'selectedState',
          getRequisition?.clientData?.clientState?.selectedState,
        );
        selectedShipmentCategory.current =
          getRequisition?.clientData?.clientState?.selectedState;
      }

      if (getRequisition?.clientData?.clientState?.urgentRequirement) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_CURRENT_STEP,
          payload:
            getRequisition?.clientData.clientState?.urgentRequirement
              ?.urgentRequirementCurrentStep,
        });
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_STEPS_INDICATOR,
          payload:
            getRequisition?.clientData?.clientState?.urgentRequirement
              ?.urgentReqStepsProcessIndicator,
        });
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION,
          payload:
            getRequisition?.clientData?.clientState?.urgentRequirement
              ?.urgentReqJustification,
        });
      }

      if (getRequisition?.clientData.clientState?.areq) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_AREQ_JUSTIFICATION,
          payload:
            getRequisition?.clientData?.clientState?.areq?.areqJustification,
        });
      }

      if (getRequisition?.clientData?.clientState?.masRequirement) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_CURRENT_STEP,
          payload:
            getRequisition?.clientData?.clientState?.masRequirement
              ?.masRequirementCurrentStep,
        });
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_STEPS_INDICATOR,
          payload:
            getRequisition?.clientData.clientState?.masRequirement
              ?.masReqStepsProcessIndicator,
        });
      }

      if (isAreqType(getRequisition?.requisitionType)) {
        dispatch({
          type: VehicleRequisitionContextActions.SET_IS_AREQ,
          payload: true,
        });
      }
      const customPaintOptionsFrmDraft =
        getRequisition?.clientData?.selectedOptions?.customerInputs
          ?.paintAndGraphicsOptions;
      if (customPaintOptionsFrmDraft) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC,
          payload: customPaintOptionsFrmDraft,
        });
      }

      const checkSupportingDocsExist =
        getRequisition?.clientData?.selectedOptions.supportingDocuments;
      if (checkSupportingDocsExist) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_TOTAL_UPLOADED_FILES,
          payload: checkSupportingDocsExist,
        });
      }
    },
  });

  const [getUserAgencies] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    onCompleted: (info) => {
      if (info?.getAgenciesByPermission) {
        const foundAgency = info.getAgenciesByPermission.find((agency) => {
          return agency.id === sessionStorage.getItem('agencyCode');
        });
        sessionStorage.setItem('isAgencyDod', Boolean(foundAgency?.isDodInt));
      }
    },
    onError: () => {},
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const standardItem = JSON.parse(sessionStorage.getItem('standardItem'));
    const requisitionTransactionType = getRequisitionTransactionType(
      sessionStorage.getItem('agencyCode'),
      sessionStorage.getItem('bureauCode'),
    );
    dispatch({
      type: VehicleRequisitionContextActions.RESET_REQUISITION_STATE,
    });
    getUserAgencies({
      variables: {
        subject: StoreSubjects.Requisition,
        operation: 'view',
      },
    });
    if (draftId) {
      getDraftData({ variables: { requisitionId: draftId } });
      getattachmentTypes();
    }

    if (standardItem?.id && !draftId) {
      getStandardItemData({
        variables: {
          filter: { operator: 'EQ', key: 'id', value: standardItem?.id },
        },
      });
      getActiveContractsData({
        variables: {
          standardItemId: standardItem?.id,
          transactionType: requisitionTransactionType,
        },
      });
      getOptionalReqData({
        variables: { standardItemId: parseInt(standardItem?.id, 10) },
      });
    }
  }, []);

  useEffect(() => {
    const availableValidationState =
      requisitionValidationState || requisitionCartValidations;
    if (
      availableValidationState &&
      requisitionType !== REQUISITION_TYPE.URGENT_REQUIREMENT
    ) {
      setValidationsModal(true);
      setSystemChanges(availableValidationState);
      requisitionCartValidations = availableValidationState;
      setValidationProperties();
    }
  }, [requisitionValidationState, requisitionCartValidations, requisitionType]);

  useEffect(() => {
    if (standardItemData) {
      const standardItemDt = standardItemData.getStandardItem;
      if (standardItemDt) {
        sessionStorage.setItem('standardItem', JSON.stringify(standardItemDt));
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_CURRENT_STANDARD_ITEM,
        payload: standardItemDt,
      });
    }
  }, [standardItemData]);

  useEffect(() => {
    if (activeContractsData && fuelCodes) {
      const contractsData =
        activeContractsData.getActivePurchaseReqContractModLinesForSIN;
      if (contractsData.length === 0) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_INACTIVE_CONTRACT,
          payload: true,
        });
      }
      const simplifiedContractsData = getSimplifiedContractData(
        contractsData,
        fuelCodes,
      );
      // console.log('activeContractsData', simplifiedContractsData);
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ALL_ACTIVE_CONTRACTS,
        payload: simplifiedContractsData,
      });
      dispatch({
        type: VehicleRequisitionContextActions.EXCLUDE_AREQ_OPTION,
        payload: !contractsData?.some((x) =>
          x.contractLineEcs.map((y) => y.equipmentCode).includes('AREQ'),
        ),
      });
    }
  }, [activeContractsData, fuelCodes]);

  useEffect(() => {
    if (allActiveContracts && optionalReqData) {
      const { allAvailableOptionalReqData, deliveryOptions } =
        getGroupedOptionalRequirementsData(
          optionalReqData.getOptionalOptions,
          allActiveContracts,
          selectedShipmentCategory.current,
        );

      if (selectedOptionsForPrice) {
        allAvailableOptionalReqData?.forEach((optionCategory) => {
          optionCategory.options.forEach((optn) => {
            const isOptionSelected = selectedOptionsForPrice.find(
              (opt) => opt.optionCode === optn.optionCode,
            );
            if (isOptionSelected) {
              /* eslint-disable no-param-reassign */
              optn.isChecked = true;
              optn.optionValue = isOptionSelected.optionQuantity
                ? isOptionSelected.optionQuantity
                : 1;
              /* eslint-enable no-param-reassign */
            }
          });
        });
        deliveryOptions.forEach((optCat) => {
          optCat.options.forEach((optn) => {
            const isOptionSelected = selectedOptionsForPrice.find(
              (opt) => opt.optionCode === optn.optionCode,
            );
            if (isOptionSelected) {
              /* eslint-disable no-param-reassign */
              optn.isChecked = true;
              optn.optionValue = isOptionSelected.optionQuantity
                ? isOptionSelected.optionQuantity
                : 1;
              /* eslint-enable no-param-reassign */
            }
          });
        });
      }

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ALL_OPTIONAL_REQS,
        payload: cloneDeep(allAvailableOptionalReqData),
        optionalReqData,
        deliveryOptions,
      });
    }
  }, [allActiveContracts, optionalReqData, selectedShipmentCategory]);

  useEffect(() => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_VEHICLE_REQUISITION_METHODS,
      getPurchaseCollisionDataOnYes,
      sin,
      draftId,
    });
    return () => {
      dispatch({
        type: VehicleRequisitionContextActions.RESET_REQUISITION_STATE,
      });
    };
  }, []);

  const onClickReview = () => {
    setValidationsModal(false);

    if (
      systemChanges.systemAvailability &&
      systemChanges.systemAvailability.isSINexpired
    ) {
      history.push('vehicle-listings');
    } else {
      dispatch({
        type: VehicleRequisitionContextActions.RESET_EXPIRED_SELECTED_CONTRACT_INFO,
        currentStep: {
          current: 1,
          text: 'Compare and select',
        },
      });
    }
  };

  const turnOffLoading = () => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
      payload: false,
    });
  };

  const onClickCancel = () => {
    if (draftRequisition) {
      setRequsitionAction({
        requisition: {
          requisitionId: draftRequisition.requisitionId,
          name: draftRequisition.friendlyName,
        },
        action: 'cancelRequisition',
        comment: 'SIN is no longer available',
      });
      turnOffLoading();
      history.push('my-requisitions');
    }
  };

  const isSINexpired = systemChanges?.systemAvailability?.isSINexpired || false;
  const backButtonLabel = draftId
    ? 'Return to my requisitions'
    : 'Cancel this requisition';
  const historyToPush = draftId
    ? 'my-requisitions'
    : 'my-requisitions?cancelUnsavedRequisition=1';
  const actions = !isSINexpired ? (
    <>
      <Button
        type="button"
        data-testid="modal-cancel-button"
        variant="outline"
        onClick={() => {
          setValidationsModal(false);
          turnOffLoading();
          history.push(historyToPush);
        }}
        label={backButtonLabel}
      />

      <Button
        type="button"
        data-testid="modal-edit-button"
        onClick={() => {
          onClickReview();
          turnOffLoading();
        }}
        label="Edit requisition"
      />
    </>
  ) : (
    <Button type="button" onClick={onClickCancel} label="Cancel requisition" />
  );

  const checkIfUrgentRequirement = draftId || isUrgentReq;

  return (
    <>
      {(activeContractsDataLoading ||
        optionalReqLoading ||
        standardItemDataLoading) && (
        <div className="afp-modal-overlay draft-requisition-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      )}
      {!activeContractsDataLoading &&
        !optionalReqLoading &&
        !standardItemDataLoading && (
          <>
            {((currentSin && allActiveContracts) ||
              checkIfUrgentRequirement) && (
              <WrappedComponent lastStepRef={lastStepRef} />
            )}
            {isValidationErrorExist && (
              <div
                className="afp-modal-overlay modalContainer"
                data-testid="sys-change-modal"
              >
                <Modal
                  className="system-change-found-modal"
                  onClose={() => {
                    setValidationsModal(false);
                  }}
                  actions={actions}
                >
                  {systemChangeModalData && (
                    <div className="modal-body">
                      <div className="title">
                        {systemChangeModalData.modalTitle}
                      </div>

                      {!isSINexpired && (
                        <div className="box">
                          {systemChanges.systemAvailability &&
                            systemChanges.systemAvailability.isSINexpired && (
                              <div className="warning-message">
                                <span>
                                  Selected make/model is now unavailable
                                </span>
                              </div>
                            )}

                          {systemChanges.validationErrors &&
                            systemChanges.validationErrors.map((item) => (
                              <div className="warning-message">
                                <span>{item}</span>
                              </div>
                            ))}
                        </div>
                      )}

                      <div className="sub-title">
                        {systemChangeModalData.modalSubTitle}
                      </div>
                    </div>
                  )}
                </Modal>
              </div>
            )}
          </>
        )}
    </>
  );
};

export default withDraftRequisition;
